
import { mapActions } from "vuex";
export default {
    methods: {
        ...mapActions(["Alert_"]),
        alertMessage(type, message) {
            this.Alert_({
                text: message,
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: type,
            });
        }
    }
}