
<template>
  <v-row class="form-conat pa-0 ma-0">
    <v-col cols="12" class="pa-0 ma-0">
      <label v-if="label" class="text-caption">{{label}}</label>

      <v-text-field
        v-if="!value"
        required
        v-model="phone"
        type="tel"
        style="border-radius: 10px"
        filled
       
        @keyup.enter="$emit('actions')"
        full-width
        rounded
        v-mask="'##########'"
        :prefix="`+${Country.callingCodes[0]}`"
      >
        <template v-slot:prepend-inner>
          <div :class="{'wrap-flag':true, 'block-control':hiddenFlag}">
            <v-menu
              offset-y
              transition="slide-x-transition"
              :close-on-content-click="false"
              bottom
              rounded="lg"

              right
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="flag rounded-lg mr-2" v-bind="attrs" v-on="on">
                  <img
                    :src="`${require(`@/countries/` + Country.flag)}`"
                    alt=""
                    srcset=""
                  />

                  <v-icon  v-if="!hiddenFlag">mdi-menu-down</v-icon>
                </div>
              </template>
              <v-card> </v-card>
              <v-list height="250px" class="list" v-if="Countries">
                <v-data-iterator
                  hide-default-footer
                  :search="search"
                  :items="Countries"
                  no-data-text="⏳ Cargando datos..."
                  no-results-text=""
                >
                  <template v-slot:no-results>
                    <span class="ma-2 text-center"
                      >Busqueda sin resultados</span
                    >
                  </template>
                  <template v-slot:header>
                    <v-text-field
                      placeholder="Buscar País"
                      class="mx-2"
                      filled
                      rounded
                      append-icon="mdi-magnify"
                      v-model="search"
                      dense
                      hide-details=""
                    >
                    </v-text-field>
                  </template>
                  <template v-slot:default="props">
                    <v-list-item
                      v-for="(item, index) in props.items"
                      :key="index"
                      class="list-item"
                      @click="Country = item"
                    >
                      <div class="img-text">
                        <img
                          :src="`${require(`@/countries/` + item.flag)}`"
                          alt=""
                          srcset=""
                        />
                        <span class="ml-2">
                          <b>{{ item.name }} </b> +{{ item.callingCodes[0] }}
                        </span>
                      </div>
                    </v-list-item>
                  </template>
                </v-data-iterator>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-text-field>
      <v-text-field
        v-else
        required
        v-model="phone"
        @keydown="isNumber($event)"
        type="tel"
        class="rounded-lg"
        filled
        readonly
        full-width
        hide-details
        @keyup.enter="$emit('actions')"
        rounded
        :prefix="`+${code.callingCodes[0]}`"
      >
        <template v-slot:prepend-inner>
          <div class="wrap-flag">
            <div class="flag rounded-lg mr-2">
              <img
                :src="`${require(`@/countries/` + code.flag)}`"
                alt=""
                srcset=""
              />

              <!-- <v-icon>mdi-menu-down</v-icon> -->
            </div>
          </div>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>
<script>
import countries from "../countries/countries.json";
import country from "../countries/country.json";
import { db, fb } from "@/firebase";
export default {
  name: "inputPhone",
  props: ["value", "code","hiddenFlag","label"],
  data() {
    return {
      Countries: countries,
      Country: country,
      phone: null,
      search: null,
    };
  },

  methods: {
    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },
  },
  watch: {
    phone(e) {
      this.$emit("retunValue", e, this.Country);
    },
    Country(a) {
      this.$emit("retunValue", this.phone, a);
    },
  },
  mounted() {
    if (this.value) {
      this.phone = this.value;
    }
  },
};
</script>
<style lang="scss" >
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  margin-right: auto;
  padding-right: 4px;
  margin-top: 10px !important;
}
.wrap-flag {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .flag {
    height: 36px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40px;
      height: 30px;
      border-radius: 8px;
    }
    .select-flag {
      height: 250px;
      width: 100px;
      left: 0;
      top: 0;
      overflow-y: scroll;
      position: absolute;
      background-color: #f2f2f2;
      z-index: 100;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: rgba(0, 0, 0, 0.435) 2px 2px 20px;
    }
  }
}
.list {
  background-color: #f2f2f2;
  overflow: scroll;
  border-radius: 20px;
}

// @include responsive(desktop) {
// 	font-size: 20px;
// 	//... all what you want
// }
ul {
  padding-left: 5px !important;
  li {
    list-style: none;
  }
}
.list {
  background-color: #f2f2f2;
  overflow: scroll;
  border-radius: 20px;
}
.img-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 30px;
    height: 23px;
    border-radius: 5px;
  }
}
.form-conat {
  width: 100%;
}
.flex-start {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  margin-right: auto;
  padding-right: 4px;
  margin-top: 10px !important;
}
.block-control{
  pointer-events: none !important;
}
</style>