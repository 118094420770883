<template>
  <v-card style="border-radius: 15px">
    <v-card-title> Cambiar mi número </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pt-4">
      <div class="wrap" v-if="!otpSent">
        <inputPhone
          :hiddenFlag="true"
          @retunValue="oldphone"
          label="Ingresa número actual"
        />
        <inputPhone
          :hiddenFlag="true"
          @retunValue="newPhone"
          label="Ingresa el número nuevo"
        />
      </div>

      <div v-if="otpSent">
        <label for="a"><b>Ingresar Código de verificación</b> </label>
        <p class="text-caption">
          Se envió un mensaje de texto a tu nuevo número de teléfono con el
          código de verificación
        </p>
        <v-otp-input
          id="a"
          v-model="otp"
          length="6"
          type="tel"
          @finish="sentOtpValidation"
          :disabled="loading"
        ></v-otp-input>

        <v-overlay color="white" absolute :value="loading">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-overlay>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text :loading="loading" @click="$emit('close')"> cancelar </v-btn>
      <v-btn
        v-if="!otpSent"
        color="primary"
        :loading="loading"
        @click="changePhonefn"
      >
        Aceptar
      </v-btn>
      <v-btn
        v-else
        color="primary"
        :loading="loading"
        @click="sentOtpValidation"
      >
        Enviar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script >
import { db, fb } from "@/firebase";
import inputPhone from "./inputPhone.vue";
import { mapActions } from "vuex";
import alertMessage from "@/mixins/alertMixin";

export default {
  name: "phoneChange",
  components: {
    inputPhone,
  },
  mixins: [alertMessage],
  data() {
    return {
      oldPhoneNumber: null,
      newPhoneNumber: null,
      code: null,
      loading: false,
      otpSent: false,
      otp: null,
    };
  },
  methods: {
    ...mapActions(["Alert_"]),
    oldphone(e, code) {
      this.oldPhoneNumber = e;
      this.code = code;
    },
    newPhone(e, code) {
      this.newPhoneNumber = e;
      this.code = code;
    },
    sentOtpValidation() {
      this.loading = true;
      if (!this.otp) {
        this.alertMessage("error", "Ingresa los campos solicitados");
        this.loading = false;
        return;
      }

      var httpClientsValidateChangePhoneToken = fb
        .functions()
        .httpsCallable("httpClientsValidateChangePhoneToken");
      var data = {
        token: this.otp,
      };
      httpClientsValidateChangePhoneToken(data)
        .then((response) => {
          console.debug(response);
          this.otpSent = false;
          this.loading = false;
          this.otp = null;
          this.alertMessage("success", "Número cambiado exitosamente");
          this.$emit("close");
        })
        .catch((error) => {
          this.alertMessage("error", "Token de autenticación incorrecto.");
          this.otpSent = true;
          this.otp = null;
          this.loading = false;
        });
    },
    changePhonefn() {
      this.loading = true;
      if (!this.oldPhoneNumber || !this.newPhoneNumber) {
        this.alertMessage("error", "Ingresa los campos solicitados");
        this.loading = false;
        return;
      }

      var data = {
        oldPhoneNumber: `${this.code.callingCodes[0]}${this.oldPhoneNumber}`,
        newPhoneNumber: `${this.code.callingCodes[0]}${this.newPhoneNumber}`,
      };
      var httpClientsChangePhoneNumber = fb
        .functions()
        .httpsCallable("httpClientsChangePhoneNumber");
      httpClientsChangePhoneNumber(data)
        .then((response) => {
          console.debug(response);
          this.otpSent = true;
          this.loading = false;
        })
        .catch((error) => {
          this.alertMessage("error", error);
          this.otpSent = false;
          this.loading = false;
        });
    },
  },
};
</script>