import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    
    themes: {
      light: {
        secondary: "#c1d72f",
        primary: "#071e49",
        tertiary: "#c0c1c2",
      },

      dark: {
        primary: "#002c6a",
        secondary: "#c1d72f",
        tertiary: "#c0c1c2",
      },
    },
  },

  icons: {
    iconfont: "fa",
  },
});
