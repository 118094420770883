<template>
  <v-card>
    <v-toolbar color="primary" dark class="white--text">
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          font-weight: bold;
        "
      >
        USUARIO BLOQUEADO
      </div>
    </v-toolbar>
    <v-card-text>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <v-icon class="mt-10" x-large color="warning">fas fa-warning</v-icon>
        <p class="mt-10" style="font-size: 20px; color:black">
          El usuario ha sido inhabilitado temporalmente, por favor comunicarse
          al número
          <a href="tel:+50494438318">+50494438318</a> para más información.
        </p>
      </div>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn text @click="$emit('success')">Aceptar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "user-blocked",
};
</script>

<style scoped>
</style>