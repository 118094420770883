<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          font-weight: bold;
        "
      >
        TÉRMINOS Y CONDICIONES
      </div>
    </v-toolbar>
    <v-card-text>
      <div class="header-logo mt-4">
        <img src="@/assets/logo_2.png" alt="" height="60px" srcset="" />
      </div>
      <h3 class="text-center mt-4">
        TÉRMINOS Y CONDICIONES DEL PROGRAMA DE LEALTAD PARA TRABAJADORES DE LA
        CONSTRUCCIÓN “GANAMÁS”
      </h3>

      <h4>Introducción</h4>
      <p class="text-caption">
        Estos términos y condiciones, en adelante referidos como “Términos del
        Programa” o simplemente “Términos”, correspondientes al denominado
        <b
          >Programa de Lealtad para Trabajadores de la Construcción “GANAMÁS”</b
        >
        de Argos Honduras S.A. de C.V., en adelante referido como el “Programa”,
        cuyo grupo focal son los albañiles, maestros de obra y demás
        trabajadores de la construcción, e ingenieros en ejercicio profesional
        independiente, así como los comercios revendedores de productos marca
        Argos y sus dependientes, en adelante “Participante” o “Participantes”,
        quienes para gozar de los beneficios del Programa se sujetarán al
        reglamento del mismo, en adelante referido como el “Reglamento”. <br />
        <br />
        Para efectos del Programa, los Términos y Condiciones, el Reglamento y
        demás estipulaciones que formen parte del Programa, Argos Honduras S.A.
        de C.V., Cementos Argos S.A. y sus filiales, funcionarios, empleados y
        partes vinculadas, se identificarán bajo la denominación “Argos”; no
        obstante, es Argos Honduras S.A. de C.V. quien asume de manera directa
        los derechos y responsabilidades que se originen por la implementación
        del Programa. <br />
        <br />
        El Programa se implementará en etapas incrementales, por lo que el
        Reglamento del Programa se aplicará de manera interpretativa por Argos,
        adaptándose a la etapa de implementación en cada momento específico. Los
        presentes términos y condiciones son válidos y vinculantes desde el
        momento del registro o vinculación de los Participantes, sea cual sea la
        etapa de implementación del Programa y su Reglamento.
      </p>
      <h4>Elegibilidad</h4>
      <p class="text-caption">
        Podrán vincularse al Programa todos los albañiles, maestros de obra y
        demás trabajadores de la construcción, e ingenieros en ejercicio
        profesional independiente que efectúen compras de los productos Argos
        que forman parte del Programa, en los establecimientos comerciales o
        ferreterías, revendedores de productos Argos. Argos seleccionará, de su
        base de clientes, los comercios a ser vinculados al Programa.
      </p>
      <h4>Modificaciones</h4>
      <p class="text-caption">
        Argos, en su condición de propietario del Programa, se reserva el
        derecho de modificar, o finalizar, cualquiera de los componentes del
        Programa, en cualquier momento y sin previa notificación, entendiéndose
        que el Participante acepta tales modificaciones por el hecho de
        permanecer vinculado al Programa.
      </p>
      <h4>Marketing y comunicaciones</h4>
      <p class="text-caption">
        Con su vinculación, usted autoriza a Argos para enviarle mensajes de
        texto, mensajes de WhatsApp y correos electrónicos relevantes que
        incluyen, entre otros, información sobre el Programa, ofertas
        promocionales de productos nuevos o existentes y actualizaciones
        relacionadas con su registro en el Programa.
      </p>
      <h4>Tratamiento de datos personales</h4>
      <p class="text-caption">
        La información que proporcione al registrarse en el Programa, así como
        los registros recopilados mientras esté vinculado al mismo, se
        almacenarán para un adecuado manejo del Programa. Usted acepta que esta
        información se puede compartir con partes externas a efectos de
        garantizar el correcto funcionamiento del Programa y el goce de los
        beneficios que el mismo le genera.
      </p>
      <h4>Limitación de responsabilidad</h4>
      <p class="text-caption">
        Argos no será responsable por los problemas o fallas técnicas que puedan
        afectar o interrumpir la operación del Programa, incluidos pero sin
        limitarse a: errores de hardware o software; fallas en la computadora,
        teléfono, cable, satélite, red, falla electrónica, inalámbrica o de
        Internet en conexión u otro problema de comunicación en línea; errores o
        limitaciones de cualquier proveedor de servicios de Internet,
        servidores, computadoras primarias o proveedores de teléfonos;
        transmisiones de datos confusas, mezcladas o defectuosas; falla en
        cualquier transmisión por correo electrónico o telefónica que se envía o
        recibe; correos electrónicos perdidos, retrasados, demorados, confusos,
        dañados, malversados, no entregados o interceptados; inaccesibilidad al
        sitio web o sistema telefónico en su totalidad o de manera parcial por
        cualquier motivo; congestión de tránsito en Internet o el sitio web o
        las líneas de teléfono. <br />
        <br />
        Tampoco será responsable por intervención humana no autorizada de la
        operación del Programa, incluyendo sin limitación a: error humano,
        manipulación humana, piratería, fraude, robo, virus, errores, gusanos, y
        otros similares. Argos no será responsable por la destrucción de
        cualquier aspecto del Programa o datos del mismo, incluyendo, sin
        limitación, la manipulación o piratería de las líneas telefónicas, o la
        pérdida, equivocación en la cuenta, malversación, inaccesibilidad o no
        disponibilidad de la cuenta de un Participante utilizada en relación con
        el Programa. No habrá responsabilidad por ningún daño personal o de
        propiedad o pérdidas de cualquier tipo que pueda sufrir el equipo de la
        computadora de un Participante u otra persona como resultado de la
        vinculación al Programa, ni por el uso o descarga de cualquier
        información del sitio web. La utilización del sitio web corre por cuenta
        y riesgo del Participante. Argos tampoco será responsable por el uso,
        mal uso, uso negligente o uso doloso, ni de las consecuencias de tales
        usos, que el Participante o cualquier tercero pueda realizar con los
        beneficios del Programa que hayan sido percibidos por el Participante.
      </p>
      <h4>Terminación del programa</h4>
      <p class="text-caption">
        Argos podrá cancelar este Programa en cualquier momento a su discreción,
        brindando un período de 30 días para que los Participantes puedan
        utilizar los beneficios a que tengan derecho dentro del Programa.
        Transcurrido dicho término, no habrá responsabilidad subsecuente.
      </p>
      <h4>Aceptación</h4>
      <p class="text-caption">
        La revisión, entendimiento y aceptación de los Términos y del Reglamento
        del Programa se asume por el solo hecho de realizar el registro o
        vinculación por el Participante o el Cliente. El Reglamento se encuentra
        a disposición de los Participantes en el sitio web
        <a target="_blank" href="https://wl-argos.web.app/menu"
          >https://wl-argos.web.app/menu</a
        >. <br />
        <br />
        Al registrarse o vincularse al Programa, usted acepta estos Términos, el
        Reglamento del Programa, así como nuestra política de privacidad.
        También acepta que Argos Honduras S.A. de C.V. puede cambiar estos
        Términos en cualquier momento sin previo aviso, incluso si afectan los
        beneficios actuales o futuros derivados del Programa, y que este
        Programa puede finalizar en cualquier momento, en cuyo caso no se
        ofrecerá responsabilidad ni compensación a los Participantes. <br />
        <br />
        El presente documento, y el Reglamento, contienen información muy
        importante sobre sus derechos, obligaciones, limitaciones, cláusulas
        excluyentes y demás términos y condiciones que debe conocer como
        Participante en el Programa. <br />
        <br />
        En caso de que cambie su intención de acatar estos Términos y
        condiciones y/o el Reglamento, por favor solicite su desvinculación del
        Programa.
        <i
          >Los presentes Términos y Condiciones, así como el Reglamento del
          Plan, han sido actualizados en fecha 1 de diciembre de 2023.</i
        >
      </p>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        :loading="loading"
        color="#161843"
        class="white--text"
        @click="accept"
        >He leído y acepto <br> los términos y condiciones</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
  
  <script>
import { mapState } from "vuex";
import { db } from "@/firebase";

export default {
  props: ["collection"],
  name: "conditions-accept",
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async accept() {
      this.loading = true;

      await db.collection(this.collection).doc(this.user.id).update({
        acceptedConditions: true,
      });

      this.loading = false;
      this.$emit("success");
    },
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>
  
  <style scoped lang="scss">
.header-logo {
  background-color: #c1d72f;
  height: 150px;
  width: 100%;
  position: relative;

  img {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
</style>