import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";

import store from "./store";

import VueMask from "v-mask";
import "./firebase";
import firebase from "firebase/compat/app";
import VueFirestore from "vue-firestore";
import back from "./components/back.vue";

Vue.use(VueFirestore);
Vue.use(firebase);
Vue.use(VueMask);
Vue.component("back", back);

Object.defineProperty(Vue.prototype, "firebase", {
  get() {
    return this.$root.firebase;
  },
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
