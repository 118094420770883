import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    meta: {
      appFrame: false,
      navBar: false,
      allowedRoles: [],
      transitionName: "fade",
    },
    component: () => import("../views/index"),
  },
  // {
  //   path: "/",
  //   redirect: () => {
  //     return "/index";
  //   },
  // },
  {
    path: "/login",
    name: "login",
    meta: {
      appFrame: false,
      navBar: false,
      allowedRoles: [],
      transitionName: "zoomIn-SlideOut",
    },
    component: () => import("../views/login"),
  },
  {
    path: "/menu",
    name: "menu",
    meta: {
      appFrame: false,
      navBar: false,
      allowedRoles: [],
      transitionName: "zoomIn-SlideOut",
    },
    component: () => import("../views/new-menu"),
  },
  {
    path: "/menuClient",
    name: "menuClient",
    meta: {
      appFrame: false,
      navBar: false,
      allowedRoles: ["client"],
      transitionName: "zoomIn-SlideOut",
    },
    component: () => import("../views/lobyClients.vue"),
  },
  {
    path: "/loginC",
    name: "loginCompras",
    meta: {
      appFrame: false,
      navBar: false,
      allowedRoles: [],
      transitionName: "zoomIn-SlideOut",
    },
    component: () => import("../views/loginCompras"),
  },

  {
    path: "/store",
    name: "store",
    meta: {
      appFrame: true,
      navBar: true,
      searchShow: true,
      btnBack: true,
      allowedRoles: ["client"],
      transitionName: "zoomIn-SlideOut",
    },
    component: () => import("../views/store/index"),
  },
  {
    path: "/lobby",
    name: "lobby",
    meta: {
      appFrame: false,
      navBar: false,
      searchShow: false,
      allowedRoles: ["client"],
      transitionName: "zoomIn-SlideOut",
    },
    component: () => import("../views/lobyUsersClients.vue"),
  },

  // {
  //   path: "/business-store",
  //   name: "business-store",
  //   meta: {
  //     appFrame: true,
  //     navBar: true,
  //     searchShow: true,
  //     transitionName: "zoomIn-SlideOut",
  //   },
  //   component: () => import("../views/store/business"),
  // },

  {
    path: "/manual-store",
    name: "manual-store",
    meta: {
      appFrame: true,
      navBar: true,
      searchShow: true,
      allowedRoles: ["client"],
      transitionName: "zoomIn-SlideOut",
    },
    component: () => import("../views/store/seller"),
  },

  // {
  //   path: "/store/seller",
  //   name: "store-seller",
  //   meta: {
  //     appFrame: true,
  //     navBar: true,
  //     searchShow: true,
  //     allowedRoles: ["client"],
  //   },
  //   component: () => import("../views/store-seller"),
  // },
  {
    path: "/claimed",
    name: "claimed",
    meta: {
      appFrame: true,
      navBar: true,
      searchShow: true,
      btnBack: true,
      allowedRoles: ["client"],
      transitionName: "zoomIn-SlideOut",
    },
    component: () => import("../views/store/couponClaimed"),
  },
  {
    path: "/where-to-redeem",
    name: "where-to-redeem",
    meta: {
      appFrame: true,
      navBar: true,
      searchShow: true,
      btnBack: true,
      allowedRoles: ["client"],
      transitionName: "zoomIn-SlideOut",
    },
    component: () => import("../views/whereToRedeem"),
  },

  {
    path: "/users/accumulations",
    name: "my-accumulations",
    meta: {
      appFrame: true,
      navBar: true,
      searchShow: false,
      btnBack: true,
      allowedRoles: ["client"],
      transitionName: "zoomIn-SlideOut",
    },
    component: () => import("../views/users/myAccumulations"),
  },

  {
    path: "/users/redemptions",
    name: "my-redemptions",
    meta: {
      appFrame: true,
      navBar: true,
      searchShow: false,
      btnBack: true,
      allowedRoles: ["client"],
      transitionName: "zoomIn-SlideOut",
    },
    component: () => import("../views/users/myRedemptions"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
