<template>
  <div @click="$emit('back')" class="back-btn">
    <v-btn color="white" x-large icon>
      <v-icon>fas fa-arrow-circle-left</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "back-btn",
};
</script>

<style scoped lang="scss">
@import "../_responsive.scss";
.back-btn {
  background-color: #071e49;
  position: absolute;
  bottom: 15%;
  left: 40%;
  padding-left: 7px;
  border-radius: 0 10px 10px 0;

  @include responsive(mobile) {
    bottom: 7%;
    left: 0%;
  }
}
</style>